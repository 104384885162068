import React, { useState, useEffect } from "react";
import { formatDate } from "../utils/format";
import Loader from "./Loader";

const NotificationLog = ({ data, isLoading }) => {
  return (
    <div className="card-body">
      <div className="scrool_table scrool_table3">
        {isLoading ? (
          <Loader />
        ) : (
          <table className="table table-dark table-hover table-striped ">
            <thead>
              <tr>
                <th>Acct</th>
                <th>Title</th>
                <th>Massage</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.user_name}</td>
                  <td>{item.title}</td>
                  <td>{item.message}</td>
                  <td>{formatDate(item.created_at)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default NotificationLog;
