import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import DataCell from "./DataCell";

const AccountTable = ({ data, onAccountClick, previousData }) => {
  const createOneNo = (no1, no2) => {
    return Number(no1 + no2).toFixed(2);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "user_name",
        header: "Acct",
        size: 10,
        Cell: ({ row }) => (
          <div onClick={() => onAccountClick(row.original.userId)}>
            {row.original.user_name}
          </div>
        ),
      },
      {
        accessorKey: "user_lock",
        header: "Status",
        size: 10,
        Cell: ({ row }) => (
          <div>
            <span
              className={
                row.original.user_lock === "1" ? "color-grn" : "color-red"
              }
            >
              {row.original.user_lock === "1" ? "Enable" : "Disable"}
            </span>
          </div>
        ),
      },
      {
        accessorKey: "net_quantity",
        header: "Net Qty",
        size: 10,
        Cell: ({ row }) => {
          const prevItem = previousData[row.index] || {};
          const previous = createOneNo(
            Number(prevItem.net_quantity_closed),
            Number(prevItem.net_quantity_open)
          );
          const newValue = createOneNo(
            Number(row.original.net_quantity_closed),
            Number(row.original.net_quantity_open)
          );
          return <DataCell value={newValue} previousValue={previous} />;
        },
      },
      {
        accessorKey: "net_quantity_open",
        header: "Open Qty",
        size: 10,
        Cell: ({ row }) => {
          const prevItem = previousData[row.index] || {};
          return (
            <DataCell
              value={row.original.net_quantity_open}
              previousValue={prevItem.net_quantity_open}
            />
          );
        },
      },
      {
        accessorKey: "net_quantity_closed",
        header: "Close Qty",
        size: 10,
        Cell: ({ row }) => {
          const prevItem = previousData[row.index] || {};
          return (
            <DataCell
              value={row.original.net_quantity_closed}
              previousValue={prevItem.net_quantity_closed}
            />
          );
        },
      },
      {
        accessorKey: "realized_pl",
        header: "$Real",
        size: 10,
        Cell: ({ row }) => {
          const prevItem = previousData[row.index] || {};
          return (
            <DataCell
              value={row.original.realized_pl}
              previousValue={prevItem.realized_pl}
            />
          );
        },
      },
      {
        accessorKey: "unrealized_pl",
        header: "$Unreal",
        size: 10,
        Cell: ({ row }) => {
          const prevItem = previousData[row.index] || {};
          return (
            <DataCell
              value={row.original.unrealized_pl}
              previousValue={prevItem.unrealized_pl || 0}
            />
          );
        },
      },
      {
        accessorKey: "maxLoss",
        header: "Max Loss",
        size: 10,
        Cell: ({ row }) => {
          const prevItem = previousData[row.index] || {};
          return (
            <DataCell
              value={row.original.maxLoss}
              previousValue={prevItem.maxLoss || 0}
            />
          );
        },
      },
      {
        accessorKey: "total_pl",
        header: "$Total P/L",
        size: 10,
        Cell: ({ row }) => {
          const prevItem = previousData[row.index] || {};
          const previous = createOneNo(
            Number(prevItem.realized_pl),
            Number(prevItem.unrealized_pl)
          );
          const newValue = createOneNo(
            Number(row.original.realized_pl),
            Number(row.original.unrealized_pl)
          );
          return <DataCell value={newValue} previousValue={previous} />;
        },
        // Cell: ({ row }) => {
        //   const prevItem = previousData[row.index] || {};
        //   return (
        //     <DataCell
        //       value={row.original.total_pl}
        //       previousValue={prevItem.total_pl || 0}
        //     />
        //   );
        // },
      },
      {
        accessorKey: "total_qty_bought",
        header: "Qty Bot",
        size: 10,
        Cell: ({ row }) => {
          const prevItem = previousData[row.index] || {};
          return (
            <DataCell
              value={row.original.total_qty_bought}
              previousValue={prevItem.total_qty_bought || 0}
            />
          );
        },
      },
      {
        accessorKey: "total_qty_sold",
        header: "Qty Sld",
        size: 10,
        Cell: ({ row }) => {
          const prevItem = previousData[row.index] || {};
          return (
            <DataCell
              value={row.original.total_qty_sold}
              previousValue={prevItem.total_qty_sold || 0}
            />
          );
        },
      },
      {
        accessorKey: "long_exposure",
        header: "$Long Exp",
        size: 10,
        Cell: ({ row }) => {
          const prevItem = previousData[row.index] || {};
          return (
            <DataCell
              value={row.original.long_exposure}
              previousValue={prevItem.long_exposure || 0}
            />
          );
        },
      },
      {
        accessorKey: "short_exposure",
        header: "$Short Exp",
        size: 10,
        Cell: ({ row }) => {
          const prevItem = previousData[row.index] || {};
          return (
            <DataCell
              value={row.original.short_exposure}
              previousValue={prevItem.short_exposure || 0}
            />
          );
        },
      },
    ],
    [previousData, onAccountClick]
  );

  const table = useMaterialReactTable({
    columns,
    data,
    columnFilterDisplayMode: "popover",
    columnDefType: "display",
    enableClickToCopy: false,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableColumnFilter: false,
    enableColumnOrdering: false,
    enableEditing: false,
    enableGlobalFilter: false,
    enableGrouping: false,
    enableHiding: false,
    enableResizing: false,
    enableStickyHeader: false,
    // enableStickyFooter: true,
    enableSorting: true,
    enablePagination: false,
    enableKeyboardShortcuts: false,
    enableColumnFilters: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    initialState: { density: "compact" },
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#090c10", //change default background color
    }),

    muiTableProps: {
      sx: {
        // border: "1px solid rgba(81, 81, 81, .5)",

        caption: {
          captionSide: "top",
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        // border: "1px solid rgba(81, 81, 81, .5)",
        fontSize: "12px",

        fontWeight: "bold",
        // fontStyle: "italic",
        // fontWeight: "normal",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        // border: "1px solid rgba(81, 81, 81, .5)",
        fontSize: "12px",
        // fontWeight: "bold",
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => onAccountClick(row.original.user_id),

      // setRowSelection((prev) => ({
      //   ...prev,
      //   [row.id]: !prev[row.id],
      // })),
      //   selected: rowSelection[row.id],
      sx: {
        cursor: "pointer",
      },
    }),
  });

  return <MaterialReactTable table={table} />;
};

export default AccountTable;
