import React, { useEffect, useState } from "react";
import "./Accounts.css";
import Loader from "./Loader";
import AccountTable from "./AccountTable";
const Accounts = ({ data, onAccountClick, isLoading }) => {
  const [previousData, setPreviousData] = useState([]);

  useEffect(() => {
    setPreviousData(data);
  }, [data]);

  return (
    <div className={isLoading ? "loader-center" : "card-body min-height "}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="scroll_table scroll_table2">
          <AccountTable
            data={data}
            onAccountClick={onAccountClick}
            previousData={previousData}
          />
          {/* <AccountTable data={data} /> */}
          {/* <table className="table table-dark table-hover table-striped text-end">
            <thead>
              <tr>
                <th className="text-start">Acct</th>
                <th>Status</th>
                <th>Net Qty</th>
                <th>Open Qty</th>
                <th>Close Qty</th>
                <th>$Real</th>
                <th>$Unreal</th>
                <th>Max Loss</th>
                <th>$Total P/L</th>
                <th>Qty Bot</th>
                <th>Qty Sld</th>
                <th>$Long Exp</th>
                <th>$Short Exp</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, rowIndex) => {
                const prevItem = previousData[rowIndex] || {};

                return (
                  <tr
                    key={item.user_name}
                    onClick={() => {
                      onAccountClick(item.user_id);
                      setSelectedRow(item.user_name);
                    }}
                    className={selectedRow === item.user_name ? "selected" : ""}
                  >
                    <td className="text-start">{item.user_name}</td>
                    <td>
                      <span
                        className={
                          item.user_lock === "1" ? "color-grn" : "color-red"
                        }
                      >
                        {item.user_lock === "1" ? "Enable" : "Disable"}
                      </span>{" "}
                    </td>
                    <DataCell
                      value={createOneNo(
                        Number(item.net_quantity_closed),
                        Number(item.net_quantity_open)
                      )}
                      previousValue={createOneNo(
                        Number(prevItem.net_quantity_closed),
                        Number(prevItem.net_quantity_open)
                      )}
                    />
                    <DataCell
                      value={item.net_quantity_open}
                      previousValue={prevItem.net_quantity_open}
                    />
                    <DataCell
                      value={item.net_quantity_closed}
                      previousValue={prevItem.net_quantity_closed}
                    />
                    <DataCell
                      value={item.realized_pl}
                      previousValue={prevItem.realized_pl}
                    />
                    <DataCell
                      value={item.unrealized_pl}
                      previousValue={prevItem.unrealized_pl}
                    />
                    <DataCell
                      value={item.maxLoss}
                      previousValue={prevItem.maxLoss}
                    />
                    <DataCell
                      value={item.total_pl}
                      previousValue={prevItem.total_pl}
                    />
                    <DataCell
                      value={item.total_qty_bought}
                      previousValue={prevItem.total_qty_bought}
                    />
                    <DataCell
                      value={item.total_qty_sold}
                      previousValue={prevItem.total_qty_sold}
                    />
                    <DataCell
                      value={item.long_exposure}
                      previousValue={prevItem.long_exposure}
                    />
                    <DataCell
                      value={item.short_exposure}
                      previousValue={prevItem.short_exposure}
                    />
                  </tr>
                );
              })}
            </tbody>
          </table> */}
        </div>
      )}
    </div>
  );
};

export default Accounts;
