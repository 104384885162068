import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import "./App.css";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ThemeProvider,
  createTheme,
  useColorScheme,
} from "@mui/material/styles";
function App() {
  const navigate = useNavigate();
  const { mode, setMode } = useColorScheme();
  // if (!mode) {
  //   return null;
  // }
  const { isAuthenticated } = useSelector((state) => state.auth);
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  const theme = createTheme({
    palette: {
      mode: "dark",
      appBackground: {
        light: "gray",
        dark: "black",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <Outlet />
        <Toaster position="top-right" reverseOrder={true} />
      </ThemeProvider>
    </>
  );
}

export default App;
