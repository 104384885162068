import React, { useEffect, useState } from "react";
import PositionsSummary from "./PositionsSummary";
import NotificationLog from "./NotificationLog";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/authSlice";
import { useWebSocket } from "../../websocket/WebSocketProvider";
import { useNavigate } from "react-router-dom";
import Accounts from "./Accounts";
import Logo from "./Logo";

const DashboardForex = () => {
  const { socket, disconnectSocket } = useWebSocket();
  const [accounts, setAccounts] = useState([]);
  const [positionSummaryData, setPositionSummaryData] = useState([]);
  const [notificationsData, setNotificationsData] = useState([]);
  const [notificationLoading, setNotificationsLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [accountsLoading, setAccountsLoading] = useState(true);
  const [positionSummaryLoading, setPositionSummaryLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (socket) {
      setAccountsLoading(true);
      setNotificationsLoading(true);
      socket.emit("getAccountsDataFX");
      socket.emit("riskNotificationLogs");
      socket.emit("stopAcctData", { type: "forex" });

      socket.on("accountsDataFX", (data) => {
        setAccounts(data);
        setAccountsLoading(false);
      });

      socket.on("riskNotificationLogsResult", (data) => {
        setNotificationsData(data);
        setNotificationsLoading(false);
      });

      socket.on("positionSummaryData", (data) => {
        setPositionSummaryData(data);
        setPositionSummaryLoading(false);
      });

      return () => {
        socket.off("accountsDataFX");
        socket.off("positionSummaryData");
      };
    }
  }, [socket]);

  useEffect(() => {
    if (accounts.length > 0 && !selectedUserId) {
      const firstUserId = accounts[0].user_id;
      setSelectedUserId(firstUserId);
      setPositionSummaryLoading(true);
      if (socket) {
        socket.emit("getPositionSummary", {
          userId: firstUserId,
          type: "forex",
        });
      }
    }
  }, [accounts, selectedUserId, socket]);

  const handleAccountClick = (userId) => {
    setSelectedUserId(userId);
    setPositionSummaryLoading(true);
    if (socket) {
      socket.emit("getPositionSummary", {
        userId,
        type: "forex",
      });
    }
  };

  useEffect(() => {
    if (selectedUserId) {
      handleAccountClick(selectedUserId);
    }
    // setTimeout(() => {
    // }, [2000]);
  }, [selectedUserId]);

  const handleLogout = () => {
    dispatch(logout());
    disconnectSocket();
    navigate("/", { replace: true });
  };

  return (
    <section className="dashboard_section">
      <div className="row m-0">
        <div className="col-md-4 p-0">
          <PositionsSummary
            isLoading={positionSummaryLoading}
            data={positionSummaryData}
          />
        </div>
        <div className="col-md-8 p-0">
          <div className="card ">
            <Logo handleLogout={handleLogout} />
            <Accounts
              isLoading={accountsLoading}
              data={accounts}
              onAccountClick={handleAccountClick}
            />
          </div>
          <div className="card ">
            <h6 className="mb-0 h6_hadding">Notification Log</h6>
            <NotificationLog
              isLoading={notificationLoading}
              data={notificationsData}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardForex;
